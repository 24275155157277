export default {
	root: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center',
	},
	content: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	},
	bottom: {
		flex: 0,
	},
};
