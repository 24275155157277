import React, { useState, useEffect } from 'react';

import { CLOUDINARY_UPLOAD_URL } from '../../config';
import firebase from '../../firebase';
import LoaderScreen from '../Loader';
import Text from '../../components/Text';
import AppBar from '../../components/AppBar';
import UserAvatar from '../../components/UserAvatar';
import { Button, LogoutButton } from '../../components';

import useStyles from './Profile.styles';
import SelectComponent from '../../components/SelectComponent';

const ProfileScreen = (props) => {
	const classes = useStyles();

	const [uploading, setUploading] = useState(false);

	const [enterprise, setEnterpise] = useState();

	const [rol, setRol] = useState();

	const [charge, setCharge] = useState();

	const [user, setUser] = useState();

	const [document, setDoc] = useState();

	const handleChangePicture = async (event) => {
		const [file] = Array.from(event.target.files);

		if (!file) {
			return;
		}

		// TODO Check file type and size

		setUploading(true);

		const formData = new FormData();

		formData.append('upload_preset', 'ml_default');

		formData.append('file', file);

		try {
			const res = await fetch(CLOUDINARY_UPLOAD_URL, {
				method: 'POST',
				body: formData,
			});

			if (res.ok) {
				const data = await res.json();
				firebase
					.firestore()
					.collection('employees')
					.doc(document)
					.update({
						avatar: data.secure_url,
					});
			}
		} finally {
			setUploading(false);
		}
	};

	useEffect(() => {
		firebase
			.firestore()
			.collection('employees')
			.where('Uid', '==', firebase.auth().currentUser.uid)
			.limit(1)
			.get()
			.then((docs) => {
				docs.forEach((doc) => {
					setDoc(doc.id);
					setUser(doc.data());
					setEnterpise(doc.data().Empresa);
					setRol(doc.data().Rol);
					setCharge(doc.data().cargo);
				});
			});
	}, []);
	return (
		<div className={classes.root}>
			<AppBar title="Mi perfil" navAction={null} />

			<div className={classes.content}>
				{!user ? (
					<LoaderScreen />
				) : (
					<>
						<label htmlFor="upload-profile-picture">
							<input
								id="upload-profile-picture"
								type="file"
								disabled={uploading}
								style={{ display: 'none' }}
								onChange={handleChangePicture}
							/>

							<UserAvatar user={user} className={classes.avatar} />
						</label>

						<Text variant="h6" color="primary">
							{user.Nombre}
						</Text>

						<Text variant="caption">
							{rol} en
							{enterprise}
						</Text>

						<div className={classes.buttons}>
							<Button disabled={uploading} component="label" htmlFor="upload-profile-picture">
								Cambiar imagen
							</Button>

							<LogoutButton>Cerrar sesión</LogoutButton>
						</div>
						{charge ? (
							<SelectComponent defaultEnterprise={enterprise} defaultRol={rol} charge={charge} />
						) : (
							<div />
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default ProfileScreen;
