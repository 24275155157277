import React from 'react';
import { Redirect } from 'react-router-dom';
import { Viewer, withContext } from '../context';

class Logout extends React.Component {
	componentDidMount() {
		// eslint-disable-next-line react/destructuring-assignment
		this.props.viewer.logout();
	}

	render() {
		return <Redirect to="/" />;
	}
}

export default withContext(Logout, {
	viewer: Viewer,
});
