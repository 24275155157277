export default {
	root: {
		padding: 50,
		height: '100%',
		boxSizing: 'border-box',
	},
	logo: {
		maxWidth: '165pt',
		textAlign: 'center',
		margin: '0 auto',
	},
};
