import React from 'react';

import MUIAvatar from '@material-ui/core/Avatar';

const Avatar = (props) => {
	const { style: customStyle, size = 118, ...restProps } = props;

	const style = {
		width: size,
		height: size,
		...customStyle,
	};

	return <MUIAvatar style={style} imgProps={{ width: size, height: size }} {...restProps} />;
};

export default Avatar;
