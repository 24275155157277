import React from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import styles from './PagesButton.styles';

const PagesButton = (props) => {
	const { onClick, color, variant, disabled, root, nameButton, classes } = props;

	const isDisabled = () => disabled === 0;

	const stylesButton = () => {
		if (root === 'ok' && isDisabled()) {
			return classes.disabled;
		}
		if (root === 'ok') {
			return classes.variant;
		}
		return classes.root;
	};
	return (
		<Button onClick={onClick} color={color} variant={variant} className={stylesButton()} disabled={isDisabled()}>
			<strong>{nameButton}</strong>
		</Button>
	);
};
export default withStyles(styles)(PagesButton);
