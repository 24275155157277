import Container from './Container';

class BottomContainer extends Container {
	search = '';

	reset = 0;

	setSearch = (search) => {
		this.search = search;
	};

	resetSearch = () => {
		this.reset += 1;
		this.setSearch('');
		if (document.getElementById('search')) {
			document.getElementById('search').value = '';
		}
	};
}

export default BottomContainer;
