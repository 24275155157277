import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import CustomComponent from './customcomponent';
import LoaderScreen from '../../Loader';
import firebase from '../../../firebase/firebase';
import PagesButton from '../../../components/PagesButton';

import styles from './Principal.styles';
import Headboard from '../../../components/Headboard';

const Empezar = (props) => {
	const { classes, match } = props;
	const [counter, setCounter] = useState(0);

	const [standard, setStandard] = useState();

	const [slide, setSlide] = useState([]);

	const db = firebase.firestore();

	const docId = match.params.id;

	let abc = 0;

	function increaseCounter() {
		if (counter < abc - 1) {
			setCounter(counter + 1);
		}
	}

	function decreaseCounter() {
		if (counter > 0) {
			setCounter(counter - 1);
		}
	}

	useEffect(() => {
		db.collection('slideStandard')
			.where('UidStandard', '==', docId)
			.get()
			.then((docs) => {
				const arrayTemp = [];
				docs.forEach((doc) => {
					arrayTemp.push(doc.data());
				});

				setSlide(arrayTemp);
			});
		db.collection('standards')
			.doc(docId)
			.get()
			.then((result) => {
				setStandard(result.data());
			});
	}, [docId]);

	if (standard && slide.length !== 0) {
		abc = slide.length;
	}

	return (
		<>
			{standard === undefined ? (
				<LoaderScreen />
			) : (
				<Grid container spacing={0} className={classes.root}>
					<Grid container item xs={12}>
						<Headboard
							title={standard.Bloque}
							subtitle={standard.Shortname}
							titleAlign="center"
							value={(counter + 1) * (100 / abc)}
						/>

						<CustomComponent
							standard={
								slide.sort((a, b) => {
									if (parseInt(a.Order) > parseInt(b.Order)) {
										return 1;
									}
									if (parseInt(a.Order) < parseInt(b.Order)) {
										return -1;
									}
									return 0;
								})[counter]
							}
						/>

						<Grid container item xs={12} className={classes.buttons} spacing={2}>
							<Grid item container xs={6} justify="flex-end">
								<PagesButton
									onClick={() => decreaseCounter()}
									color="default"
									root="ok"
									disabled={counter}
									nameButton="Anterior"
								/>
							</Grid>
							<Grid item xs={6} container justify="flex-start">
								{counter === abc - 1 ? (
									<PagesButton
										variant="contained"
										color="secondary"
										onClick={() => props.history.push(`/repo/${docId}/done`)}
										nameButton="Finalizar"
									/>
								) : (
									<PagesButton
										onClick={() => increaseCounter()}
										variant="contained"
										color="secondary"
										nameButton="Siguiente"
									/>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default withStyles(styles)(Empezar);
