import React from 'react';
import { Grid, Typography, List, ListItem, ListItemIcon } from '@material-ui/core';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import useStyles from './ListComponent.styles';

const ListComponent = (props) => {
	const classes = useStyles();
	const { list, title } = props;

	return (
		<Grid container direction="column" className={classes.container}>
			<Typography variant="h5" className={classes.title}>
				<strong>{title}</strong>
			</Typography>
			<List>
				{list.map((item) => (
					<ListItem>
						<ListItemIcon className={classes.icon}>
							<Brightness1Icon className={classes.root} />
						</ListItemIcon>
						<Typography className={classes.text}>
							<strong>{item}</strong>
						</Typography>
					</ListItem>
				))}
			</List>
		</Grid>
	);
};
export default ListComponent;
