import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Typography, Tab, Grid } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';
import Bottomnavigation from '../../components/bottomnavigation/bottomnavigation';
import check from '../../img/check.png';
import wrong from '../../img/wrong.png';
import star from '../../img/star.png';
import idea from '../../img/idea.png';
import useStyles from './Home.styles';
import LoaderScreen from '../Loader';
import firebase from '../../firebase';

import Card from '../../components/Card';
import General from '../../components/General';
import { withContext, Time } from '../../context';
// import newDB from '../../firebase/passProdToDev';

const Home = () => {
  const classes = useStyles();

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`TabsHome-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        <Box className={classes.tabs} p={2}>
          {children}
        </Box>
      </Typography>
    );
  }

  const db = firebase.firestore();

  const dbMessages = db.collection('messages');
  const [mesClient, setMesClient] = useState();
  const [mesInfo, setMesInfo] = useState();
  const [mesDiary, setMesDiary] = useState();
  const [company, setCompany] = useState();
  const [rol, setRol] = useState();
  const user = firebase.auth().currentUser.uid;
  const date = new Date();
  const [value, setValue] = useState(0);
  const regEx = /[\u2000-\u206F\u2E00-\u2E7F\u0300-\u036f\s\\'!"#$%&()*+,\-./:;<=>?@[\]^_`{|}~]/g;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const normalize = (data) =>
      data
        ? data
            .toUpperCase()
            .normalize('NFD')
            .replace(regEx, '')
        : null;
    // newDB()

    if (user) {
      db.collection('employees')
        .where('Uid', '==', user)
        .get()
        .then((docs) => {
          docs.forEach((doc) => {
            setCompany(doc.data().Empresa);
            setRol(doc.data().Rol);
          });
        });
    }

    if (rol) {
      dbMessages
        .where('UidEmployee', '==', user)
        .where('Empresa', '==', company)
        .where('status', '==', 'on')
        .get()
        .then((docs) => {
          const arrayMessagesClient = [];
          const arrayMessagesInfo = [];

          docs.forEach((doc) => {
            const data = doc.data()

            const finished = new Date(data.finished);
            const rol = normalize(data.rol);
            const type = normalize(data.type);

            if ((rol === normalize(rol) || rol === 'TODOS' || rol === null) && finished > date) {
              if (type === 'CLIENTEMISTERIOSO') {
                arrayMessagesClient.push(data);
              }
              if (type === 'INFO' || type === 'INFORMATION') {
                arrayMessagesInfo.push(data);
              }
            }
          });

          setMesClient(arrayMessagesClient);
          setMesInfo(arrayMessagesInfo);
        });

      dbMessages
        .where('UidEmployee', '==', user)
        .where('type', '==', 'DIARIO')
        .where('Empresa', '==', company)
        .get()
        .then((docs) => {
          const arrayMessagesDiary = [];

          docs.forEach((doc) => {
            const data = doc.data()

            if (!data.Uid) {
              return
            }

            const rol = normalize(data.rol);

            if (rol === normalize(rol) || rol === 'TODOS' || rol === null) {
              arrayMessagesDiary.push(data);
            }
          });

          setMesDiary(arrayMessagesDiary);
        });
    }
  }, [user, rol, company]);

  return (
    <div className={classes.root}>
      {!user ? (
        <LoaderScreen />
      ) : (
        <>
          <Typography variant="h5" className={classes.text}>
            Bienvenido, {user.NombreChapa}
          </Typography>
          <Card className={classes.tabs}>
            <Tabs
              centered
              value={value}
              onChange={handleChange}
              aria-label="TabsHome"
              TabIndicatorProps={{
                style: {
                  display: 'none',
                },
              }}
            >
              <Tab
                className={classnames(classes.tab, { [classes.active]: value === 0 })}
                label="General"
              />
              <Tab
                className={classnames(classes.tab, { [classes.active]: value === 1 })}
                label="Mis actividades"
              />
            </Tabs>
          </Card>
          <TabPanel value={value} index={0}>
            <Grid container spacing={3}>
              <General
                check={check}
                wrong={wrong}
                idea={idea}
                star={star}
                db={db}
                user={user}
                company={company}
                rol={rol}
                client={mesClient}
                diary={mesDiary}
                info={mesInfo}
                date={date}
                general
              />
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container spacing={3}>
              <General
                check={check}
                wrong={wrong}
                idea={idea}
                star={star}
                db={db}
                user={user}
                company={company}
                rol={rol}
                client={mesClient}
                diary={mesDiary}
                info={mesInfo}
                date={date}
              />
            </Grid>
          </TabPanel>
          <div className={classes.safariSpace} />
          <Bottomnavigation />
        </>
      )}
    </div>
  );
};

export default withContext(Home, {
  time: Time,
});
