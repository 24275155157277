import React from 'react';

import { Router, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Route from './Route';
import { withContext, Viewer } from '../context';

import Repositorio from '../screens/repositorio/Repositorio';
import Imagen from '../screens/imagen/imagen';
import Empezar from '../screens/imagen/empezar/empezar';
import Finalizer from '../screens/imagen/empezar/finalizer/Finish';
import Login from '../screens/login/Login';
import Logout from '../screens/Logout';
import ProfileScreen from '../screens/profile/Profile';
import Home from '../screens/home/Home';

import { MainLayout } from '../layouts';

import PrivateRoute from './PrivateRoute';

const history = createBrowserHistory();

const CustomRoutes = (props) => (
	<Router history={history}>
		<Route exact path="/login" component={() => (props.viewer.isAnonymous ? <Login /> : <Redirect to="/home" />)} />

		<Route exact path="/logout" component={Logout} />

		<Route
			path="/repo"
			layout={MainLayout}
			layoutProps={{
				bottomTab: 'repo',
			}}
			component={() => (
				<>
					<PrivateRoute exact path="/repo" component={Repositorio} />
					<PrivateRoute exact path="/repo/:id" component={Imagen} />
					<PrivateRoute exact path="/repo/:id/start" component={Empezar} />
					<PrivateRoute exact path="/repo/:id/done" component={Finalizer} />
				</>
			)}
		/>

		<Route
			path="/profile"
			layout={MainLayout}
			layoutProps={{
				bottomTab: 'profile',
			}}
			component={() => <PrivateRoute exact path="/profile" component={ProfileScreen} />}
		/>

		<Route path="/" component={() => <Redirect to={props.viewer.isAnonymous ? '/login' : '/home'} />} />

		<Route
			path="/home"
			layout={MainLayout}
			layoutProps={{
				bottomTab: 'home',
			}}
			component={() => <PrivateRoute exact path="/home" component={Home} />}
		/>
	</Router>
);

export default withContext(CustomRoutes, {
	viewer: Viewer,
});
