import React, { useReducer } from 'react';
import Message from './Message';
import objReducer from '../context/dateReducer';

const Subtype = (props) => {
	const { db, type, company, rol, wrong, check, idea } = props;
	const [state, dispatch] = useReducer(objReducer.reducer, objReducer.initialState);
	const handleClick = () => {
		dispatch({ type: 'send' });
	};
	return (
		<>
			{type.subtype === '0' && (
				<Message
					title={type.bloque}
					subtitle={type.shortname}
					company={company}
					rol={rol}
					message={type.message}
					type="wrong"
					img={wrong}
					onClick={handleClick}
				/>
			)}
			{type.subtype === '1' && (
				<Message
					title={type.bloque}
					subtitle={type.shortname}
					company={company}
					rol={rol}
					message={type.message}
					type="check"
					img={check}
					onClick={handleClick}
				/>
			)}
			{type.subtype !== '1' && type.subtype !== '0' && (
				<Message
					title={type.bloque}
					subtitle={type.shortname}
					company={company}
					rol={rol}
					message={type.message}
					type="idea"
					img={idea}
					onClick={handleClick}
				/>
			)}
		</>
	);
};

export default Subtype;
