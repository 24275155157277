const objReducer = new Object();

objReducer.initialState = { started: null };
objReducer.reducer = (state, action) => {
	switch (action.type) {
		case 'send':
			state.started = Date();
			break;
		case 'save':
			return state.started;
		default:
			throw new Error();
	}
};

export default objReducer;
