export default {
	root: {
		marginRight: '30px',
		width: 140,
	},
	image: {
		width: 100,
		paddingLeftt: 5,
	},
	text: {
		display: 'block',
		width: 150,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		paddingTop: '1em',
		color: 'black',
	},
	link: {
		textDecoration: 'none !important',
		'&:hover': {
			textDecoration: 'none !important',
		},
	},
};
