import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import image from '../img/noActivities.PNG';
import styles from './NoActivities.styles';

const NoActivities = (props) => {
	const { classes } = props;

	return (
		<Grid container className={classes.root} justify="center">
			<Grid item>
				<img src={image} alt="" />
			</Grid>
			<Grid item>
				<Typography>
					<strong>No tienes actividades</strong>
				</Typography>
				<Typography>
					Cuando se activen nuevas actividades recibirás una notificación para consultarlas.
				</Typography>
			</Grid>
		</Grid>
	);
};

export default withStyles(styles)(NoActivities);
