import {
	MuiThemeProvider as ThemeProvider,
	createMuiTheme as createTheme,
	withStyles,
	withTheme,
} from '@material-ui/core/styles';

const theme = createTheme({
	palette: {
		primary: {
			main: '#003B4C',
		},
		secondary: {
			main: '#4F86A0',
		},
	},

	overrides: {
		MuiButton: {
			root: {
				borderRadius: 200,
				minHeight: '32pt',
			},
			label: {
				textTransform: 'none',
				fontWeight: 'bold',
			},
		},
		MuiTab: {
			root: {
				maxWidth: 'initial',
			},
		},
	},
});

export default theme;

export { ThemeProvider, createTheme, withStyles, withTheme };
