import React from 'react';

import Grid from '@material-ui/core/Grid';
import { Dialog, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Textonly from './Textonly';

const useStyles = makeStyles((theme) => ({
	image: {
		borderRadius: '100%',
		marginBottom: '20px',
		height: 150,
	},

	root: {
		margin: 0,
		textAlign: 'center',
		alignContent: 'center',
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
		zIndex: '1',
	},

	imageModal: {
		height: '100%',
		width: '100%',
		objectFit: 'cover',
	},
	modal: {
		height: '100%',
		width: '100%',
	},
}));

const ZoomImage = (props) => {
	const { src, text } = props;

	const [open, setOpen] = React.useState(false);

	const classes = useStyles();

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const getImage = () => {
		const image = src.split('/');
		image.splice(6, 0, 'w_150,h_150,c_fill');

		return image.join('/');
	};

	const getImageModal = () => {
		const image = src.split('/');
		image.splice(6, 0, 'w_0.2,h_0.2');

		return image.join('/');
	};
	return (
		<Grid container spacing={0} className={classes.root} justify="center">
			<img src={getImage()} className={classes.image} onClick={handleClickOpen} alt="ZoomImage" />

			<Dialog fullScreen className={classes.modal} open={open}>
				<IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
					<CloseIcon />
				</IconButton>

				<img className={classes.imageModal} srcSet={getImageModal()} alt="ZoomImage" />
			</Dialog>

			<Textonly strong={text} />
		</Grid>
	);
};
export default ZoomImage;
