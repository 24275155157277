import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ImageContentHover from 'react-image-hover';
import { withStyles } from '@material-ui/core';
import BottomNavigation from '../../../components/bottomnavigation/bottomnavigation';
import Textonly from '../../../components/Textonly';
import ZoomImage from '../../../components/ZoomImage';
import VideoApp from '../../../components/VideoApp';
import styles from './Principal.styles';
import TextImage from '../../../components/TextImage';
import ListComponent from '../../../components/ListComponent';
import Download from '../../../components/Download';
import { Loader } from '../../../components';

const CustomComponent = (props) => {
	const { standard } = props;

	const type = standard.Type.toUpperCase();

	const result = Object.keys(standard)
		.filter((content) => content !== 'Order' && content !== 'Type')
		.sort();

	if (type === 'MULTIMEDIA') {
		return (
			<>
				<Grid container spacing={0}>
					<Grid item xs={12}>
						<img
							src={standard.content2}
							alt="head"
							style={{
								width: '100%',
								height: '300px',
								objectFit: 'cover',
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography align="center" color="secondary" gutterBottom>
							<strong>{standard.content1}</strong>
						</Typography>
					</Grid>
				</Grid>
				<BottomNavigation />
			</>
		);
	}
	if (type === 'TEXTONLY') {
		return <Textonly strong={standard.content1} />;
	}
	if (type === 'VIDEO') {
		return <VideoApp src={standard.content1} />;
	}
	if (type === 'ZOOMIMAGE') {
		return (
			<>
				<ZoomImage src={standard.content2} text={standard.content1} />
				<BottomNavigation />
			</>
		);
	}
	if (type === 'QUOTE') {
		return (
			<div>
				<Grid container spacing={0} style={{ textAlign: 'center' }}>
					<Grid item xs={12}>
						This content must be graphic?
					</Grid>
					<Grid item xs={12}>
						<Typography align="center" color="secondary" gutterBottom>
							<strong>{standard.content1}</strong>
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography align="center" color="secondary" gutterBottom>
							<strong>{standard.content2}</strong>
						</Typography>
					</Grid>
				</Grid>
				<BottomNavigation />
			</div>
		);
	}
	if (type === 'LONGTEXT') {
		return (
			<div>
				<Grid container spacing={0} style={{ textAlign: 'center' }}>
					<Grid item xs={12}>
						<Typography align="center" color="secondary" gutterBottom>
							<strong>{standard.content1}</strong>
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography align="center" color="secondary" gutterBottom>
							{standard.content2}
						</Typography>
					</Grid>
				</Grid>
				<BottomNavigation />
			</div>
		);
	}
	if (type === 'NUMBERSENTENCE') {
		return (
			<div>
				<Grid container spacing={0} style={{ transform: 'translate(0%, 100%)' }}>
					<Grid item xs={12}>
						<Typography align="center" color="secondary" gutterBottom>
							<strong>{standard.content1}</strong>
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography align="center" color="secondary" gutterBottom>
							{standard.content2} {standard.content3}
						</Typography>
					</Grid>
				</Grid>
				<BottomNavigation />
			</div>
		);
	}
	if (type === 'REVEAL') {
		return (
			<div>
				<Grid container spacing={0} style={{ transform: 'translate(0%, 35%)' }}>
					<Grid item xs={12}>
						<Typography align="center" color="secondary" gutterBottom>
							<strong>{standard.content1}</strong>
						</Typography>
					</Grid>
					<Grid item xs={12} container justify="center" alignContent="center" alignItems="center">
						<ImageContentHover
							className="image-cover"
							tileWidth="300px"
							tileHeight="250px"
							image={standard.content2}
							content={{
								title: standard.content3,
								body: standard.content3,
							}}
							effect="fadeIn"
						/>
					</Grid>
				</Grid>
				<BottomNavigation />
			</div>
		);
	}
	if (type === 'TXTIMAGE') {
		if (result.length === 3) {
			return <TextImage src={standard.content3} text={standard.content2} title={standard.content1} />;
		}
		return <TextImage src={standard.content2} text={standard.content1} />;
	}
	if (type === 'LIST') {
		return <ListComponent title={standard.content1} list={standard.content2.split(';')} />;
	}
	if (type === 'DOWNLOAD') {
		return <Download title={standard.content1} text={standard.content2} />;
	}

	return <Loader />;
};

export default withStyles(styles)(CustomComponent);
