export default {
	root: {
		margin: '0 10%',
		textAlign: 'center',
		width: '100%',
		alignSelf: 'center',
	},
	text: {
		fontSize: '17pt !important',
		lineHeight: '23pt',
	},
};
