import React from 'react';
import { Grid, Typography, IconButton, Dialog } from '@material-ui/core';
import PinchToZoom from 'react-pinch-and-zoom';
import CloseIcon from '@material-ui/icons/Close';

import Textonly from './Textonly';
import useStyles from './TextImage.style';

const TextImage = (props) => {
	const classes = useStyles();
	const { src, text, title } = props;

	const [open, setOpen] = React.useState(false);

	const getImage = () => {
		const image = src.split('/');
		image.splice(6, 0, 'w_0.2,h_0.2');

		return image.join('/');
	};
	const backgroundImage = () => {
		const image = src.split('/');
		image.splice(6, 0, 'w_0.2,h_0.2,e_blur:500');

		return image.join('/');
	};

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const rootStyle = {};

	if (backgroundImage() != null) {
		rootStyle.backgroundImage = `url("${backgroundImage()}")`;
		rootStyle.height = 250;
		rootStyle.width = '100%';
		rootStyle.backgroundSize = 'cover';
		rootStyle.backgroundBlendMode = 'overlay';
		rootStyle.backgroundColor = 'rgba(0,0,0,0.2)';
	}

	return (
		<Grid container>
			<Typography className={classes.texttitle} variant="h5">
				<strong>{title}</strong>
			</Typography>

			<Textonly strong={text} />

			<Grid style={rootStyle}>
				<img srcSet={getImage()} className={classes.root} onClick={handleClickOpen} alt="cloudinaryImage" />

				<Dialog fullScreen className={classes.modal} open={open}>
					<IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
						<CloseIcon />
					</IconButton>

					<PinchToZoom className={classes.zoom}>
						<img className={classes.imageModal} srcSet={getImage()} alt="ZoomImage" />
					</PinchToZoom>
				</Dialog>
			</Grid>
		</Grid>
	);
};

export default TextImage;
