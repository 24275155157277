import { makeStyles } from '@material-ui/styles';

const AVATAR_SIZE = 118;

export default makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		marginTop: '4rem',
	},
	content: {
		flex: 1,
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
	},
	avatar: {
		width: AVATAR_SIZE,
		height: AVATAR_SIZE,
		display: 'flex',
		fontSize: AVATAR_SIZE * 0.5,
		marginTop: 20,
		marginBottom: 20,
	},
	buttons: {
		marginTop: 20,
		display: 'flex',
		flexDirection: 'column',

		'& > *': {
			marginTop: 15,
		},
	},
});
