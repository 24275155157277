import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
// import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import BottomNavigation from '../../components/bottomnavigation/bottomnavigation';

// import data from '../../data.json'
import Navbar from '../../components/navbar/navbar';
import firebase from '../../firebase/firebase';
import LoaderScreen from '../Loader';

const color = {
	color: 'white',
};

export default function Imagen(props) {
	const { match, history } = props;
	const doc = match.params.id;

	const [data, setData] = useState([]);

	const db = firebase.firestore();

	useEffect(() => {
		db.collection('standards')
			.doc(doc)
			.get()
			.then((docs) => {
				setData(docs.data());
			});
	}, []);

	if (data.length === 0) {
		return <LoaderScreen />;
	}

	const image = data.CoverImage ? data.CoverImage : data.Headimage;

	return (
		<div>
			<Grid
				container
				justify="center"
				alignContent="center"
				style={{
					backgroundImage: `url(${image})`,
					backgroundColor: 'rgba(0,0,0,0.5)',
					backgroundSize: 'cover',
					backgroundPosition: 'center center',
					backgroundRepeat: 'no-repeat',
					height: '100vh',
					backgroundBlendMode: 'overlay',
				}}
			>
				<Grid item xs={12}>
					<Navbar history={history} id={doc} />
				</Grid>
				<Grid item xs={12} container spacing={2} justify="center" alignContent="center">
					<Grid item xs={12}>
						<Typography align="center" variant="h6" gutterBottom style={color}>
							<strong>{data.Bloque.toUpperCase()}</strong>
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography align="center" gutterBottom style={color} variant="h4">
							<strong>{data.Repo}</strong>
						</Typography>
					</Grid>
					<Grid item xs={12} style={{ textAlign: 'center' }}>
						<Button
							color="secondary"
							variant="contained"
							style={{ borderRadius: '100px', width: '200px' }}
							onClick={() => props.history.push(`/repo/${doc}/start`)}
						>
							Empezar
						</Button>
					</Grid>
				</Grid>
			</Grid>

			<BottomNavigation />
		</div>
	);
}
