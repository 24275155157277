import React from 'react';
import { withStyles } from '@material-ui/core';
import ReactPlayer from 'react-player';
import styles from './VideoApp.styles';

const VideoApp = (props) => {
	const { src, classes } = props;

	const ref = React.useRef();

	window.addEventListener('orientationchange', () => {
		if (window.orientation === 90 && !document.fullscreenElement && ref.current != null) {
			ref.current.requestFullscreen().catch((error) => {
				console.error('err', error);
			});
		} else if (document.fullscreenElement) {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			}
		}
	});

	return (
		<div className={classes.component}>
			<ReactPlayer
				url={src}
				width="100%"
				playing
				id="videoPlayer"
				controls
				allowFullScreen
				ref={(node) => {
					ref.current = node;
				}}
				className="react-player"
			/>
		</div>
	);
};

export default withStyles(styles)(VideoApp);
