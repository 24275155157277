import { makeStyles } from '@material-ui/styles';

export default makeStyles({
	root: {
		margin: '60px 30px ',
		height: '100%',
	},
	text: {
		fontWeight: 'bold',
	},

	tabs: {
		padding: '0px !important',
		marginTop: '40px',
		borderRadius: '100px',
	},
	tab: {
		width: '50%',
		borderRadius: '100px',
	},
	active: {
		backgroundColor: 'black',
		color: 'white',
	},
	noActivites: {
		textAlign: 'center',
	},
	safariSpace: {
		height: 30,
	},
});
