import React from 'react';
import { Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { withStyles } from '../theme';
import styles from './Standard.styles';
import Card from './Card';

const Standard = (props) => {
	const { component, to, src, alt, variant, strong, classes } = props;

	return (
		<div className={classes.root}>
			<Link component={component} className={classes.link} to={to}>
				<Card>
					<img src={src} alt={alt} className={classes.image} />
				</Card>
				<Typography variant={variant}>
					<strong className={classes.text}>{strong}</strong>
				</Typography>
			</Link>
		</div>
	);
};

export default withStyles(styles)(Standard);
