export default {
	root: {
		borderRadius: '100px',
		padding: '0 35px',
	},
	variant: {
		borderRadius: '100px',
		border: '3px solid black',
		color: 'black',
		padding: '0 35px',
	},
	disabled: {
		border: '3px solid grey',
		borderRadius: '100px',
		color: 'black',
		padding: '0 35px',
	},
};
