import { makeStyles } from '@material-ui/styles';

export default makeStyles({
	root: {
		color: '#eb347d',
		fontSize: 'medium',
	},
	title: {
		marginLeft: 20,
	},
	icon: {
		minWidth: 25,
	},
	container: {
		justifyContent: 'center',
	},
	text: {
		fontSize: '17pt !important',
		lineHeight: '23pt',
	},
});
