import React from 'react';

import gravatarURL from 'gravatar-url';
import Avatar from './Avatar';

const Gravatar = (props) => {
	const { email = '', size = 118, ...restProps } = props;

	const url = gravatarURL(email, {
		size,
		default: 'identicon',
	});

	return <Avatar src={url} {...restProps} />;
};

export default Gravatar;
