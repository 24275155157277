import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { withRouter } from '../../routes';

import { HomeIcon, RepoIcon, ProfileIcon } from '../../icons';
import { withContext, Bottom } from '../../context';

const useStyles = makeStyles({
	root: {
		width: '100%',
		position: 'fixed',
		bottom: 0,
	},
});

const TABS = {
	home: {
		url: '/home',
		icon: HomeIcon,
	},
	repo: {
		url: '/repo',
		icon: RepoIcon,
	},
	profile: {
		url: '/profile',
		icon: ProfileIcon,
	},
};

const BottomNavigation = (props) => {
	const { history, bottom } = props;
	let { tab = 'home' } = props;
	const defaultTabIndex = Object.keys(TABS).indexOf(tab);

	const classes = useStyles();
	const [tabIndex, setTabIndex] = React.useState(defaultTabIndex);

	function handleChangeTab(_, newTabIndex) {
		setTabIndex(newTabIndex);
		const tabName = Object.keys(TABS)[newTabIndex];

		if (tabName in TABS) {
			tab = TABS[tabName];

			if (!tab.disabled && tab.url) {
				history.push(tab.url);
			}
		}
	}

	return (
		<Paper square className={classes.root} elevation={1}>
			<Tabs
				value={tabIndex}
				onChange={handleChangeTab}
				variant="fullWidth"
				indicatorColor="primary"
				textColor="primary"
			>
				{Object.keys(TABS).map((key) => {
					const { icon: Icon, disabled } = TABS[key];

					return (
						<Tab
							key={key}
							icon={<Icon />}
							disabled={disabled}
							id={`bottomTab${key}`}
							onClick={() => {
								bottom.resetSearch();
							}}
						/>
					);
				})}
			</Tabs>
		</Paper>
	);
};

export default withRouter(
	withContext(BottomNavigation, {
		bottom: Bottom,
	})
);
