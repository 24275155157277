import React from 'react';

import Avatar from './Avatar';
import Gravatar from './Gravatar';

const UserAvatar = (props) => {
	const { user, ...restProps } = props;

	if (user.avatar) {
		return <Avatar src={user.avatar} />;
	}

	if (user.Email) {
		return <Gravatar email={user.Email} />;
	}

	return (
		<Avatar {...restProps}>
			{user.Nombre.split(/\s+/, 2)
				.map((word) => word[0].toUpperCase())
				.join('')}
		</Avatar>
	);
};

export default UserAvatar;
