// Configuración desarrollo
const config = {
	apiKey: 'AIzaSyDX2R7wHHw8gV7hqqyi_zuW7iTMWsB2LBQ',
	authDomain: 'arges-15b62.firebaseapp.com',
	databaseURL: 'https://arges-15b62.firebaseio.com',
	projectId: 'arges-15b62',
	storageBucket: 'arges-15b62.appspot.com',
	messagingSenderId: '316081294594',
	appId: '1:316081294594:web:73a31f46e9631be3',
	measurementId: 'G-VWHNBZ1SVM',
};

// Configuración Test
// const config = {
//   apiKey: "AIzaSyCgCkl4BsO1KpJxdMMH_cdedBpoafKj3vY",
//   authDomain: "argestest-96c83.firebaseapp.com",
//   databaseURL: "https://argestest-96c83.firebaseio.com",
//   projectId: "argestest-96c83",
//   storageBucket: "argestest-96c83.appspot.com",
//   messagingSenderId: "687059622860",
//   appId: "1:687059622860:web:d46a13de1ae4d43d579b69"
// };

export default config;
