import Container from './Container';

class TimeContainer extends Container {
	time = '';

	timeFinished = 0;

	date = new Date();

	setTime = (time) => {
		this.time = time;
	};

	isActualDate = (date) => {
		if (this.date.getDay() !== parseInt(date)) {
			return false;
		}
		return true;
	};

	showMessage = () => !!(parseInt(this.time) <= this.date.getHours() && this.date.getHours() <= this.timeFinished);
}

export default TimeContainer;
