import React, { Fragment } from 'react';

import { Route as RRRoute } from 'react-router-dom';

const Route = (props) => {
	const { layout: Layout = Fragment, layoutProps = {}, component: Component, ...restProps } = props;

	return (
		<RRRoute
			render={() => (
				<Layout {...layoutProps}>
					<Component {...props} />
				</Layout>
			)}
			{...restProps}
		/>
	);
};

export default Route;
