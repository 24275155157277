import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import { Button, Logo, TextField } from '../../components';
import { Viewer, withContext } from '../../context';
import { withStyles } from '../../theme';

import styles from './Login.styles';

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
			messagError: '',
			intents: 0,
		};
	}
	getIntents = () => {
		return this.state.intents;
	};
	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	increment = () => {
		this.setState({ intents: this.getIntents() + 1 });
	};

	handleSubmit = async () => {
		const { viewer, history } = this.props;
		const { email, password } = this.state;
		try {
			await viewer.login(email, password);
			history.push('/');
		} catch (error) {
			this.increment();
			const message = password
				? 'La contraseña es incorrecta, prueba otra vez en 5 segundos '
				: 'Inserte una contraseña';
			// eslint-disable-next-line react/no-access-state-in-setstate
			const manyMessage =
				// eslint-disable-next-line react/no-access-state-in-setstate
				this.state.intents > 5
					? 'Demasiados intentos, pordrá repetir la contraseña en 1 minuto, si ha perdido la contraseña pongase en contacton con nosotros, gracias!'
					: message;
			this.setState({ messagError: manyMessage });
		}
	};

	render() {
		const { classes } = this.props;
		const { email, password } = this.state;

		return (
			<Grid
				container
				spacing={0}
				justify="center"
				alignContent="center"
				alignItems="center"
				className={classes.root}
			>
				<Grid item>
					<Grid container spacing={3}>
						<Grid item xs={12} className={classes.logo}>
							<Logo />
						</Grid>

						<Grid item xs={12}>
							<Grid container spacing={0}>
								<Grid item xs={12}>
									<TextField
										id="outlined-email"
										label="Email"
										type="email"
										fullWidth
										value={email}
										onChange={this.handleChange('email')}
										margin="dense"
									/>
								</Grid>

								<Grid item xs={12}>
									{!this.state.messagError ? (
										<TextField
											id="outlined-pass"
											label="Contraseña"
											type="password"
											fullWidth
											value={password}
											onChange={this.handleChange('password')}
											margin="dense"
										/>
									) : (
										<TextField
											error
											id="outlined-pass"
											label="Contraseña"
											type="password"
											helperText={this.state.messagError}
											fullWidth
											value={password}
											onChange={this.handleChange('password')}
											margin="dense"
										/>
									)}
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12}>
							<Button
								fullWidth
								onClick={this.handleSubmit}
								onKeyPress={(event) => {
									if (event.key === 'Enter') {
										this.handleSubmit();
									}
								}}
							>
								Iniciar sesión
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

export default withStyles(styles)(
	withRouter(
		withContext(Login, {
			viewer: Viewer,
		})
	)
);
