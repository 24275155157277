import { makeStyles } from '@material-ui/styles';

export default makeStyles({
	root: {
		margin: '0 20px',
	},
	textBlock: {
		textAlign: 'center',
		display: 'block',
	},
	text: {
		marginTop: 20,
		marginBottom: 40,
	},
	button: {
		backgroundColor: '#6C63FF',
		color: 'white',
		justifyContent: 'flex-start',
		paddingLeft: 6,
		'&:focus': {
			backgroundColor: '#6C63FF',
		},
	},
	containerIcon: {
		backgroundColor: '#363194',
		borderRadius: '100%',
		padding: '10px 10px 3px 10px',
		marginRight: 25,
	},
	icon: {
		color: '#6C63FF',
		transform: 'rotate(225deg)',
		fontSize: 35,
	},
	link: {
		textAlign: 'center',
	},
});
