import React from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import style from './Textonly.styles';

const Textonly = (props) => {
	const { strong, classes } = props;
	return (
		<div className={classes.root}>
			<Typography className={classes.text}>
				<strong>{strong}</strong>
			</Typography>
		</div>
	);
};

export default withStyles(style)(Textonly);
