import React, { useEffect, useState, useRef, useReducer } from 'react';
import Grid from '@material-ui/core/Grid';
import { AppBar } from '../../../../components';
import LoaderScreen from '../../../Loader';
import BottomNavigation from '../../../../components/bottomnavigation/bottomnavigation';
import firebase from '../../../../firebase/firebase';
import Textonly from '../../../../components/Textonly';
import IconButton from '../../../../components/IconButton';
import objReducer from '../../../../context/dateReducer';

const Finish = (props) => {
	const { match } = props;
	const db = firebase.firestore();
	const doc = match.params.id;
	const uidEmployee = firebase.auth().currentUser.uid;

	const [standard, setStandard] = useState();
	const [user, setUser] = useState();

	const stat = useRef();
	const document = useRef();
	const date = useRef();
	const [state, dispatch] = useReducer(objReducer.reducer, objReducer.initialState);

	const updateData = (messDoc) => {
		if (date.current !== undefined) {
			db.collection('messages')
				.doc(messDoc)
				.update({
					finished: Date(),
					status: 'off',
				});

			// firebase.firestore()
			// .collection('messages')
			// .where('started', '==', null)
			// .limit(1)
			// .get()
			// .then( docs =>{
			//   docs.forEach(doc => {
			//   firebase.firestore().collection('messages').doc(doc.id).update({
			//     status : 'on'
			//   })})
			// })
		}
	};

	const addOpinion = (userMess, message, reasoning, block, shortname) => {
		dispatch({ type: 'save' });
		const started = state.started;
		db.collection('trackingMessages').add({
			UidUser: userMess.Uid,
			name: userMess.Nombre,
			UidMessage: date.current && stat.current === 'on' ? message : '',
			date: Date(),
			opinion: reasoning,
			bloque: block,
			shortname,
			started,
		});
	};

	const searchMessage = (shortname, block) => {
		db.collection('messages')
			.where('bloque', '==', block)
			.where('UidEmployee', '==', user.Uid)
			.where('shortname', '==', shortname)
			.where('status', '==', 'on')
			.get()
			.then((docs) => {
				docs.forEach((message) => {
					document.current = message.id;
					date.current = message.data().started;
					stat.current = message.data().status;
				});
			});
	};
	useEffect(() => {
		firebase
			.firestore()
			.collection('employees')
			.where('Uid', '==', uidEmployee)
			.get()
			.then((docs) => {
				docs.forEach((employee) => setUser(employee.data()));
			});

		db.collection('standards')
			.doc(doc)
			.get()
			.then((slide) => {
				setStandard(slide.data());
			});
	}, []);

	if (standard) {
		searchMessage(standard.Shortname, standard.Bloque);
	} else {
		return <LoaderScreen />;
	}

	return (
		<Grid container>
			{standard && (
				<Grid item xs={12} container spacing={0} style={{ height: '90vh' }}>
					<Grid item xs={12}>
						<AppBar titleAlign="center" title={standard.Bloque} subtitle={standard.Shortname} />
					</Grid>

					<Grid item xs={12} display="flex" container justify="center" alignContent="flex-start">
						<Textonly alignItems="flex-start" strong="¿Te ha gustado este material?" />
						<Grid container item xs={12} justify="center" alignItems="flex-start">
							<IconButton
								onClick={() => {
									props.history.push('/repo');
									updateData(document.current);
									addOpinion(user, document.current, 'dislike', standard.Bloque, standard.Shortname);
								}}
							/>

							<IconButton
								onClick={() => {
									props.history.push('/repo');
									updateData(document.current);
									addOpinion(user, document.current, 'like', standard.Bloque, standard.Shortname);
								}}
								like="ok"
							/>
						</Grid>
					</Grid>

					<BottomNavigation />
				</Grid>
			)}
		</Grid>
	);
};
export default Finish;
