import React from 'react';
import { Card as MUICard, withStyles } from '@material-ui/core';
import styles from './Card.styles';

const Card = (props) => {
	const { classes, ...restProps } = props;

	return (
		<div className={classes.root}>
			<MUICard className={classes.container} {...restProps} />
		</div>
	);
};

export default withStyles(styles)(Card);
