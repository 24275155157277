import React from 'react';

import { Subscribe as UnstatedSubscribe } from 'unstated';

const Subscribe = (props) => {
	const { to, ...restProps } = props;

	return <UnstatedSubscribe to={[].concat(to)} {...restProps} />;
};

export default Subscribe;
