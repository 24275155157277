import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
		color: 'white',
	},
	grow: {
		flexGrow: 1,
	},
}));

export default function DenseAppBar(props) {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<AppBar position="fixed" elevation={0} style={{ backgroundColor: 'transparent' }}>
				<Toolbar variant="dense">
					<IconButton
						edge="start"
						className={classes.menuButton}
						aria-label="Menu"
						onClick={() => props.history.push('/repo')}
					>
						<ArrowBack />
					</IconButton>
					<div className={classes.grow} />
				</Toolbar>
			</AppBar>
		</div>
	);
}
