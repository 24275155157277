import React from 'react';
import { Typography, Grid, Button, Link } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import useStyles from './Download.styles';

const Download = (props) => {
	const classes = useStyles();

	const { title, text } = props;

	return (
		<Grid container alignContent="center" justify="center" direction="column" className={classes.root}>
			<Grid container item justify="center" className={classes.textBlock}>
				<Typography variant="h5">
					<strong>{title}</strong>
				</Typography>
			</Grid>
			<Link href={text} className={classes.link}>
				<Button variant="contained" size="large" className={classes.button}>
					<div className={classes.containerIcon}>
						<AttachFileIcon className={classes.icon} />
					</div>
					Archivo Adjunto
				</Button>
			</Link>
		</Grid>
	);
};

export default Download;
