import React from 'react';

import { Loader } from '../components';
import { withStyles } from '../theme';

import styles from './Loader.styles';

const LoaderScreen = ({ classes }) => (
	<div className={classes.root}>
		<Loader />
	</div>
);

export default withStyles(styles)(LoaderScreen);
