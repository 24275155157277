export default {
	root: {
		marginTop: 20,
	},
	container: {
		borderRadius: '10px',
		boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
		padding: '20px',
	},
};
