import React from 'react';

import { withContext, Viewer } from '../context';
import Button from './Button';

const LogoutButton = (props) => {
	const { viewer, ...restProps } = props;

	return <Button {...restProps} onClick={viewer.logout} />;
};

export default withContext(LogoutButton, {
	viewer: Viewer,
});
