import React from 'react';

import { withStyles } from '../theme';

import styles from './Base.styles';

const BaseLayout = (props) => {
	const { classes, children } = props;

	return <div className={classes.root}>{children}</div>;
};

export default withStyles(styles)(BaseLayout);
