import React from 'react';
import { Subscribe, Provider as ContextProvider, Viewer } from './context';
import data from './data.json';
import CustomRoutes from './routes/routes';
import theme, { ThemeProvider } from './theme';
import LoaderScreen from './screens/Loader';
import { BaseLayout } from './layouts';

import './App.css';

const App = () => (
	<ThemeProvider theme={theme}>
		<ContextProvider>
			<Subscribe to={Viewer}>
				{(viewer) => {
					if (viewer.isInitializing) {
						return (
							<BaseLayout>
								<LoaderScreen />
							</BaseLayout>
						);
					}

					return <CustomRoutes data={data} />;
				}}
			</Subscribe>
		</ContextProvider>
	</ThemeProvider>
);

export default App;
