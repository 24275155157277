import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '315px !important',
	},
	gridContainer: {
		justifyContent: 'center',
	},
	container: {
		backgroundColor: 'rgb(254, 202, 87)',
		width: '80px !important',
		height: '80px',
		padding: '20px 20px 20px 21px',
		borderRadius: '100%',
		marginBottom: '20px',
		[theme.breakpoints.up('sm')]: {
			marginRight: '20px',
		},
	},
	image: {
		width: '29pt',
	},
	text: {
		textAlign: 'center',
		alignSelf: 'center',
		marginLeft: '10px',
		[theme.breakpoints.up('sm')]: {
			textAlign: 'justify',
		},
	},
}));
export default useStyles;
