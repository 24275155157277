import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
	container: {
		height: '600px',
	},
	root: {
		width: '100%',
		maxHeight: 250,
		alignSelf: 'center',
		objectFit: 'contain',
	},
	texttitle: {
		padding: '20px 20px 0 20px',
		alignSelf: 'flex-end',
	},
	zoom: {
		display: 'flex !important',
		alignSelf: 'center',
	},
	imageModal: {
		height: '100%',
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
		zIndex: '1',
	},
	modal: {
		height: '100%',
		width: '100%',
	},
}));
