import React, { useEffect, useRef, useState } from 'react';

import Information from './Information';
import Subtype from './Subtype';
import NoActivities from './NoActivities';

const General = (props) => {
	const { db, company, rol, wrong, check, idea, star, client, info, diary, date, general } = props;

	const timeIn = useRef();
	const timeOut = useRef();
	const timeShowFinished = useRef();
	const timeShowStarted = useRef();
	const [time, setTime] = useState(true);
	const [show, setShow] = useState(false);

	const weekDays = (weekDay) => {
		if (isNaN(parseInt(weekDay))) {
			switch (
				weekDay
					.toUpperCase()
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
			) {
				case 'LUNES':
					return 1;
				case 'MARTES':
					return 2;
				case 'MIERCOLES':
					return 3;
				case 'JUEVES':
					return 4;
				case 'VIERNES':
					return 5;
				case 'SABADO':
					return 6;
				case 'DOMINGO':
					return 0;
				default:
					return 1;
			}
		} else {
			return parseInt(weekDay);
		}
	};
	const updateStatusMessage = (message) => {
		const dateFinished = new Date(message.finished);
		if (dateFinished <= date && !time) {
			db.collection('messages')
				.doc(message.Uid)
				.update({
					status: 'on',
				});
		}
	};
	const calculatedShow = (dateStarted, dateFinished) => {
		timeShowStarted.current = dateStarted - date > 0 ? dateStarted - date : 0;
		timeShowFinished.current = dateFinished - date > 0 ? dateFinished - date : 0;
	};
	const calculatedTime = (type) => {
		const completeTime = type.horas.split('h') ? type.horas.split('h') : 0;
		const minutes =
			parseInt((completeTime[0] - date.getHours()) * 60) + parseInt(completeTime[1] === '' ? 0 : completeTime[1]);
		let timeH = 0;
		if (parseInt(completeTime[0]) === date.getHours()) {
			timeH = parseInt(completeTime[1]) - date.getMinutes();
		} else if (parseInt(completeTime[0]) > date.getHours()) {
			timeH = minutes - date.getMinutes();
		}

		if (weekDays(type.dia) === date.getDay()) {
			timeIn.current = timeH * 60000;
			if (date.getMinutes() > 0) {
				timeOut.current = (24 - date.getHours() - date.getMinutes() / 60) * 3600000;
			} else {
				timeOut.current = (24 - date.getHours()) * 3600000;
			}
		}
	};

	useEffect(() => {
		setTimeout(() => {
			if (timeOut.current > 0) {
				setTime(true);
			}
		}, timeIn.current);

		setTimeout(() => {
			if (timeOut.current === 0 || timeOut.current === undefined) {
				timeOut.current = 0;
				setTime(false);
			}
		}, timeOut.current);

		setTimeout(() => {
			if (timeShowFinished.current > 0) {
				setShow(true);
			}
		}, timeShowStarted.current);
		setTimeout(() => {
			setShow(false);
			timeShowFinished.current = 0;
		}, timeShowFinished.current);
	}, [time, show]);

	return (
		<>
			{diary &&
				diary.map((message) => {
					calculatedTime(message);
					updateStatusMessage(message);
					if (weekDays(message.dia) === date.getDay() && time && message.status === 'on') {
						if (message.subtype !== 'n/a') {
							return (
								<Subtype
									db={db}
									type={message}
									wrong={wrong}
									key={message.Uid}
									check={check}
									idea={idea}
									company={company}
									rol={rol}
								/>
							);
						}
						return general && <Information key={message.Uid} text={message.message} img={star} />;
					}
				})}
			{info &&
				info.map((message) => {
					const dateStarted = new Date(message.created);
					const dateFinished = new Date(message.finished);
					calculatedShow(dateStarted, dateFinished);
					if (dateStarted <= date && dateFinished >= date && show) {
						return (
							general && (
								<Information
									title={message.bloque}
									text={message.message}
									key={message.Uid}
									img={star}
								/>
							)
						);
					}
				})}

			{client &&
				client.map((message) => (
					<Subtype
						db={db}
						key={message.Uid}
						type={message}
						wrong={wrong}
						check={check}
						idea={idea}
						company={company}
						rol={rol}
					/>
				))}
			{(client === undefined && diary === undefined && info === undefined) ||
				(client && client.length === 0 && !general && <NoActivities />)}
		</>
	);
};

export default General;
