import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import styles from './IconButton.styles';
import likeImg from '../img/like.png';
import dislike from '../img/dislike.png';

const IconButton = (props) => {
	const { like, onClick, classes } = props;
	return (
		<Button className={like === 'ok' ? classes.like : classes.dislike} onClick={onClick}>
			<img src={like === 'ok' ? likeImg : dislike} className={classes.image} alt="" />
		</Button>
	);
};

export default withStyles(styles)(IconButton);
