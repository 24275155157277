import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.down('sm')]: {
			marginTop: '56px',
		},
		[theme.breakpoints.up('sm')]: {
			marginTop: '65px',
		},

		width: '100%',
		position: 'fixed',
		zIndex: '1',
	},
}));
export default useStyles;
