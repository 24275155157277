export default {
	root: {
		width: '100%',
		marginTop: '60px',
		height: '300px',
	},
	component: {
		width: '100%',
		alignSelf: 'center',
		backgroundColor: 'rgba(0,0,0,1)',
	},
	video: {
		width: '100%',
	},
};
