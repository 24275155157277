export default {
	root: {
		background: 'white',
		opacity: 0.95,
		width: '100%',
	},
	title: {
		lineHeight: 1,
		fontSize: '12px !important',
		textTransform: 'uppercase',
		fontWeight: 500,
	},
	subtitle: {
		lineHeight: 1,
		fontSize: 12,
		marginTop: '.2em',
		fontWeight: 500,
		textTransform: 'initial',
	},
	center: {
		textAlign: 'center',
		width: '100%',
		paddingRight: '30px',
	},
};
