import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import AppBar from './AppBar';
import useStyles from './Headboard.styles';

const Headboard = (props) => {
	const classes = useStyles();
	const { value, ...restProps } = props;

	return (
		<Grid container item xs={12}>
			<AppBar {...restProps} />
			<LinearProgress className={classes.root} variant="determinate" color="secondary" value={value} />
		</Grid>
	);
};
export default Headboard;
