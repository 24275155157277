import React from 'react';
import { withRouter } from 'react-router-dom';
import MUIAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import { withStyles } from '../theme';
import styles from './AppBar.styles';

function ElevationScroll(props) {
	const { children } = props;

	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: document.getElementById('root'),
	});

	return React.cloneElement(children, {
		elevation: trigger ? 2 : 0,
	});
}

const AppBar = (props) => {
	const { classes, history, title, subtitle, titleAlign = 'left', ...restProps } = props;

	let { navAction } = props;
	if (typeof navAction === 'undefined') {
		if (history) {
			navAction = {
				icon: BackIcon,
				execute: () => {
					history.push('/repo');
				},
			};
		}
	}

	return (
		<>
			<ElevationScroll {...restProps}>
				<MUIAppBar color="default" className={classes.root} elevation={4}>
					<Toolbar>
						{navAction && (
							<IconButton edge="start" color="inherit" aria-label="menu" onClick={navAction.execute}>
								<navAction.icon />
							</IconButton>
						)}

						<div className={`${classes[titleAlign]}`}>
							{title && <Typography className={classes.title}>{title}</Typography>}

							{subtitle && (
								<Typography variant="subtitle1" color="primary" className={classes.subtitle}>
									{subtitle}
								</Typography>
							)}
						</div>
					</Toolbar>
				</MUIAppBar>
			</ElevationScroll>

			{/* <Toolbar /> */}
		</>
	);
};

export default withRouter(withStyles(styles)(AppBar));
