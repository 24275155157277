import React from 'react';

import { withStyles } from '../theme';
import BottomNavigation from '../components/bottomnavigation/bottomnavigation';

import BaseLayout from './Base';
import styles from './Main.styles';

const MainLayout = (props) => {
	const { classes, children, bottomTab, ...restProps } = props;

	return (
		<BaseLayout {...restProps}>
			<div className={classes.content}>{children}</div>

			<BottomNavigation className={classes.bottom} tab={bottomTab} />
		</BaseLayout>
	);
};

export default withStyles(styles)(MainLayout);
