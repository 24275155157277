import React from 'react';

import { withStyles } from '../theme';
import { classnames } from '../utils';
import logo from '../img/logo.png';

import styles from './Logo.styles';

const Logo = (props) => {
	const { classes, className: customClassName, ...restProps } = props;

	const className = classnames(customClassName, classes.root);

	return <img src={logo} alt="Logo" className={className} {...restProps} />;
};

export default withStyles(styles)(Logo);
