import React, { useEffect, useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from '../../routes';
import AppBar from '../../components/AppBar';
import Standard from '../../components/Standard';

import Search from './search/search';
import firebase from '../../firebase/firebase';
import LoaderScreen from '../Loader';
import { Bottom, withContext } from '../../context';

const useStyles = makeStyles({
	index: {
		display: 'contents',
	},
	title: {
		paddingBottom: 10,
		paddingTop: 30,
	},
	text: {
		paddingRight: 10,
		paddingBottom: 10,
		fontSize: 12,
	},
});
function Repositorio(props) {
	const { bottom } = props;
	const [loading, setLoading] = React.useState(true);
	const [standards, setStandards] = React.useState([]);
	const [search, setSearch] = React.useState(bottom.search);
	const classes = useStyles();

	const [role, setRole] = React.useState();
	const [company, setCompany] = React.useState();
	const [orderBlock, setOrderBlock] = useState([]);
	const [orderStandards, setOrderStandards] = useState([]);

	const regEx = /[\u2000-\u206F\u2E00-\u2E7F\u0300-\u036f\s\\'!"#$%&()*+,\-./:;<=>?@[\]^_`{|}~]/g;
	const db = firebase.firestore();

	const USER = firebase.auth().currentUser.uid;
	const copyOrderedArray = useRef([]);

	const handleChangeSearch = (event) => {
		bottom.setSearch(event.target.value.toLowerCase().trim());
		setSearch(bottom.search);
	};
	const normalizeInformation = (data) => {
		return data
			.toUpperCase()
			.normalize('NFD')
			.replace(regEx, '');
	};
	const inputSecurity = (arrayNotSort, arrayOrder) => {
		let count = 0;
		if (arrayNotSort.length !== arrayOrder.length) {
			return true;
		}

		arrayNotSort.forEach((item) => {
			if (typeof item === 'string') {
				arrayOrder.forEach((itemOrdered) => {
					if (normalizeInformation(item) === normalizeInformation(itemOrdered.block)) {
						count += 1;
					}
				});
			} else {
				arrayOrder.forEach((itemOrdered) => {
					if (normalizeInformation(item.Shortname) === normalizeInformation(itemOrdered.standard)) {
						count += 1;
					}
				});
			}
		});

		if (count === 0) {
			return true;
		}

		return false;
	};

	const sortStandards = (arrayNotSort, arrayOrder) => {
		const orderedArray = [];
		const arrayToProve = [];

		arrayNotSort.forEach((item) => arrayToProve.push(item));

		if (arrayOrder.length > copyOrderedArray.current.length) {
			arrayOrder.forEach((item) => copyOrderedArray.current.push(item));
		} else if (arrayOrder.length <= 1) {
			copyOrderedArray.current.forEach((item) => arrayOrder.push(item));
		}

		arrayOrder.sort((a, b) => {
			if (parseInt(a.Order) > parseInt(b.Order)) {
				return 1;
			} else if (parseInt(a.Order) < parseInt(b.Order)) {
				return -1;
			} else {
				return 0;
			}
		});
		arrayOrder.forEach((itemOrdered) => {
			let found = false;
			arrayNotSort = arrayNotSort.filter((item) => {
				if (!found && normalizeInformation(itemOrdered.standard) === normalizeInformation(item.Shortname)) {
					orderedArray.push(item);
					found = true;
					return false;
				}
				return true;
			});
		});

		if (inputSecurity(arrayNotSort, orderedArray)) {
			const arrayFinal = [];
			orderedArray.concat(arrayToProve).forEach((item) => {
				if (!arrayFinal.includes(item)) {
					arrayFinal.push(item);
				}
			});
			return arrayFinal;
		}
		return orderedArray;
	};

	const sortBlocks = (arrayNotSort, arrayOrder) => {
		const orderedArray = [];
		const arrayToProve = [];

		arrayNotSort.forEach((item) => arrayToProve.push(item));

		if (inputSecurity(arrayNotSort, arrayOrder)) {
			return arrayNotSort;
		}
		arrayOrder.sort((a, b) => {
			if (parseInt(a.Order) > parseInt(b.Order)) {
				return 1;
			}
			if (parseInt(a.Order) < parseInt(b.Order)) {
				return -1;
			}
			return 0;
		});

		arrayOrder.forEach((itemOrdered) => {
			let found = false;
			arrayNotSort = arrayNotSort.filter((item) => {
				if (!found && normalizeInformation(itemOrdered.block) === normalizeInformation(item)) {
					orderedArray.push(item);
					found = true;
					return false;
				}
				return true;
			});
		});
		if (inputSecurity(arrayNotSort, orderedArray)) {
			const arrayFinal = [];

			orderedArray.concat(arrayToProve).forEach((item) => {
				if (!arrayFinal.includes(item)) {
					arrayFinal.push(item);
				}
			});
			return arrayFinal;
		}
		return orderedArray;
	};

	useEffect(() => {
		db.collection('employees')
			.where('Uid', '==', USER)
			.get()
			.then((docs) => {
				docs.forEach((doc) => {
					setCompany(doc.data().Empresa);
					setRole(doc.data().Rol);
				});
			});
		if (company && role) {
			db.collection('standards')
				.where('Empresa', '==', company)
				.where('Rol', '==', role)
				.get()
				.then((docs) => {
					const repository = {};
					const arrayBlocks = [];
					const arrayStandard = [];
					docs.forEach((doc) => {
						const standard = doc.data();
						const block = standard.Bloque;

						const foundBlock = arrayBlocks.find((item) => item.block === block);
						const foundStandard = arrayBlocks.find((item) => item.standard === standard.Shortname);

						if (!foundBlock) {
							arrayBlocks.push({
								block,
								Order: parseInt(doc.data().OrdenBloque),
							});
						}

						if (!foundStandard) {
							arrayStandard.push({
								standard: standard.Shortname,
								Order: parseInt(doc.data().OrdenStandard),
							});
						}
						standard.Uid = doc.id;

						if (!(block in repository)) {
							repository[block] = [];
						}

						repository[block].push(standard);
					});

					setOrderBlock(arrayBlocks);
					setOrderStandards(arrayStandard);
					setStandards(repository);
					setLoading(false);
				});
		}

		setSearch(bottom.search);
	}, [company, role]);

	return (
		<div
			style={{
				flex: 1,
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<AppBar title="Repositorio" subtitle={role} navAction={null} />

			<div style={{ padding: 25, flex: 1, marginTop: '56px' }}>
				{loading ? (
					<LoaderScreen />
				) : (
					<Grid container spacing={4}>
						<Grid item xs={12}>
							<Search search={search} onChange={handleChangeSearch} />
						</Grid>
						<Grid item xs={12} container>
							<Grid item xs={12} className={classes.index}>
								{sortBlocks(Object.keys(standards), orderBlock).map((block) => (
									<Link href={`#${block.toUpperCase()}`} underline="none" key={block}>
										<Typography color="secondary" className={classes.text}>
											<strong>{`${block} (${standards[block].length}) `}</strong>
										</Typography>
									</Link>
								))}
							</Grid>

							{sortBlocks(Object.keys(standards), orderBlock).map((block) => {
								const blockStandards = standards[block].filter(
									(standard) =>
										!search ||
										standard.Shortname.toLowerCase()
											.normalize('NFD')
											.replace(regEx, '')
											.includes(search)
								);
								if (!blockStandards.length) {
									return null;
								}
								return (
									<React.Fragment key={block}>
										<Grid item xs={12}>
											<Typography
												color="primary"
												id={block.toUpperCase()}
												className={classes.title}
											>
												<strong>{block.toUpperCase()}</strong>
											</Typography>
										</Grid>

										<Grid container spacing={2} item xs={12} style={{ marginBottom: 40 }}>
											{sortStandards(blockStandards, orderStandards).map((standard) => (
												<Grid item xs={6} sm={4} md={3} lg={2} key={standard.Uid}>
													<Standard
														component={RouterLink}
														to={`/repo/${standard.Uid}`}
														alt={standard.Repo}
														src={standard.Headimage}
														variant="caption"
														strong={standard.Shortname || standard.Repo}
													/>
												</Grid>
											))}
										</Grid>
									</React.Fragment>
								);
							})}
						</Grid>
					</Grid>
				)}
			</div>
		</div>
	);
}

export default withContext(Repositorio, {
	bottom: Bottom,
});
