import React, { useState, useEffect } from 'react';
import { InputLabel, Select, MenuItem, Grid, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import firebase from '../firebase';

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		marginTop: 30,
	},
}));

const SelectComponent = (props) => {
	const classes = useStyles();

	const { defaultEnterprise, defaultRol, charge } = props;
	const [roles, setRol] = useState();
	const [rolKey, setRolKey] = useState(defaultRol);
	const [enterprises, setEnterprises] = useState();

	const [companyKey, setCompany] = useState(defaultEnterprise);
	const [user, setUser] = useState();

	firebase
		.firestore()
		.collection('employees')
		.where('Uid', '==', firebase.auth().currentUser.uid)
		.get()
		.then((docs) => {
			docs.forEach((doc) => {
				setUser(doc.id);
			});
		});

	const handleChangeCompany = (event) => {
		setCompany(event.target.value);
	};

	const handleChangeRol = (event) => {
		setRolKey(event.target.value);
	};

	useEffect(() => {
		firebase
			.firestore()
			.collection('employees')
			.get()
			.then((docs) => {
				const arrayTemp = [];
				docs.forEach((doc) => {
					if (!arrayTemp.includes(doc.data().Empresa)) {
						arrayTemp.push(doc.data().Empresa);
					}
				});
				setEnterprises(arrayTemp);
			});

		if (companyKey) {
			firebase
				.firestore()
				.collection('employees')
				.where('Empresa', '==', companyKey)
				.get()
				.then((docs) => {
					const arrayRol = [];
					docs.forEach((doc) => {
						if (!arrayRol.includes(doc.data().Rol)) {
							arrayRol.push(doc.data().Rol);
						}
					});
					setRol(arrayRol);
				});
		}

		if (user && rolKey && companyKey) {
			firebase
				.firestore()
				.collection('employees')
				.doc(user)
				.update({
					Empresa: companyKey,
					Rol: rolKey,
				});
		}
	}, [companyKey, rolKey, user]);

	return (
		<>
			{charge === 'superjefe' && (
				<Grid container direction="row" justify="space-around">
					<FormControl className={classes.formControl}>
						<InputLabel id="Company">Empresa</InputLabel>
						<Select labelid="Company" value={companyKey} onChange={handleChangeCompany}>
							{enterprises &&
								enterprises.map((enterprise) => (
									<MenuItem value={enterprise} key={enterprise}>
										{enterprise}
									</MenuItem>
								))}
						</Select>
					</FormControl>
					<FormControl className={classes.formControl}>
						<InputLabel id="Rol">Rol</InputLabel>
						<Select labelid="Rol" value={rolKey} onChange={handleChangeRol}>
							{roles &&
								roles.map((rol) => (
									<MenuItem value={rol} key={rol}>
										{rol}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</Grid>
			)}
			{charge === 'jefe' && (
				<FormControl className={classes.formControl}>
					<InputLabel id="Rol">Rol</InputLabel>
					<Select labelid="Rol" value={rolKey} onChange={handleChangeRol}>
						{roles &&
							roles.map((rol) => (
								<MenuItem value={rol} key={rol}>
									{rol}
								</MenuItem>
							))}
					</Select>
				</FormControl>
			)}
			{!enterprises && !roles && <div />}
		</>
	);
};

export default SelectComponent;
