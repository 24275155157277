import firebase from '../firebase';
import Container from './Container';

const INITIAL_STATE = {
	user: null,
	employee: null,
};

class ViewerContainer extends Container {
	state = {
		initializing: true,
		...INITIAL_STATE,
	};

	constructor(...args) {
		super(...args);

		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				const db = firebase.firestore();
				const employees = db.collection('employees');

				employees
					.where('Uid', '==', user.uid)
					.get()
					.then((docs) =>
						docs.forEach((employee) => {
							if (employee.exists) {
								this.setState({
									user,
									employee: employee.data(),
									initializing: false,
								});
							} else {
								this.reset();
							}
						})
					);
			} else {
				this.reset();
			}
		});
	}

	reset = () => this.setState({ initializing: false, ...INITIAL_STATE });

	get user() {
		return this.state.user;
	}

	get employee() {
		return this.state.employee;
	}

	get isAnonymous() {
		return !this.state.user;
	}

	get isInitializing() {
		return this.state.initializing;
	}

	get isInitialized() {
		return !this.state.initializing;
	}

	login = async (email, password) => {
		await firebase.auth().signInWithEmailAndPassword(email, password);
	};

	logout = async () => {
		window.location.href = 'http://arges.pro/';
		await firebase.auth().signOut();
		await this.reset();
	};
}

export default ViewerContainer;
