import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './Information.styles';
import Card from './Card';

const Information = (props) => {
	const classes = useStyles();
	const { text, img } = props;

	return (
		<Grid container item xs={12} sm={6} md={4} xl={2}>
			<div className={classes.root}>
				<Card>
					<Grid container className={classes.gridContainer}>
						<Grid item className={classes.container}>
							<img src={img} className={classes.image} alt="" />
						</Grid>
						<Grid item xs={12} sm={6} className={classes.text}>
							<Typography>{text}</Typography>
						</Grid>
					</Grid>
				</Card>
			</div>
		</Grid>
	);
};

export default Information;
