import React, { useState, useEffect } from 'react';
import { Grid, Typography, Link, makeStyles } from '@material-ui/core';
import firebase from '../firebase';
import Card from './Card';
import { Link as RouterLink } from '../routes';

const useStyles = makeStyles({
	root: {
		width: '315px',
		maxWidth: '315px',
		height: '230px',
		maxHeight: '230px',
	},
	gridContainer: {
		paddingBottom: '110px',
	},
	rootNoMessages: {
		marginTop: 30,
		textAlign: 'center',
		color: 'rgba(0,0,0,0.3)',
	},
	icon: {
		width: '60px !important',
		height: '60px',
		padding: '10px 0 0 10px',
		borderRadius: '100%',
		marginRight: '20px',
	},
	itemTitle: {
		width: '100%',
		maxWidth: 'calc(100% - 80px)',
	},
	check: {
		backgroundColor: 'rgb(29,209,161)',
	},
	wrong: {
		backgroundColor: 'rgb(255,107,107)',
	},
	idea: {
		backgroundColor: 'rgb(254, 202, 87)',
	},
	image: {
		width: '29pt',
	},
	text: {
		fontSize: '14px',
	},
	subtitle: {
		fontSize: '12px',
		maxHeight: 230,
	},
	link: {
		textDecoration: 'none !important',
		'&:hover': {
			textDecoration: 'none !important',
		},
	},
});

const Message = (props) => {
	const { title, subtitle, company, rol, img, message, type, onClick } = props;
	const classes = useStyles();
	const [route, setRoute] = useState();
	const [backgroundImage, setBackgroundImage] = useState();

	useEffect(() => {
		if (company) {
			firebase
				.firestore()
				.collection('standards')
				.where('Empresa', '==', company)
				.where('Rol', '==', rol)
				.where('Bloque', '==', title)
				.where('Shortname', '==', subtitle)
				.get()
				.then((docs) => {
					docs.forEach((doc) => {
						setBackgroundImage(doc.data().CoverImage);
						setRoute(doc.id);
					});
				});
		}
	}, [company, rol, subtitle, title]);

	const rootStyle = {};

	if (backgroundImage != null) {
		rootStyle.backgroundImage = `url("${backgroundImage}")`;
		rootStyle.backgroundSize = 'cover';
		rootStyle.color = 'white';
		rootStyle.backgroundBlendMode = 'overlay';
		rootStyle.backgroundColor = 'rgba(0,0,0,0.4)';
		rootStyle.height = 200;
	}

	return (
		<>
			{route && (
				<Grid container item xs={12} sm={6} md={4} xl={2}>
					<div className={classes.root}>
						<Link to={`/repo/${route}`} onClick={onClick} component={RouterLink} className={classes.link}>
							<Card style={rootStyle}>
								<Grid container item xs={12} className={classes.gridContainer}>
									<Grid item className={`${classes.icon} ${classes[type]}`}>
										<img src={img} className={classes.image} alt="backgroundImage" />
									</Grid>
									<Grid item className={classes.itemTitle}>
										<Typography className={classes.text}>
											<strong>{title}</strong>
										</Typography>
										<Typography className={classes.subtitle}>{subtitle}</Typography>
									</Grid>
								</Grid>
								<Grid
									container
									justify="flex-start"
									alignContent="flex-end"
									className={classes.container}
								>
									<Typography className={classes.text}>{message}</Typography>
								</Grid>
							</Card>
						</Link>
					</div>
				</Grid>
			)}
		</>
	);
};

export default Message;
