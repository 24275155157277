export default {
	root: {
		height: '100%',
		paddingBottom: '60px',
	},

	buttons: {
		height: '59px',
		alignSelf: 'flex-end',
	},
};
