import React from 'react';

import { withContext, Viewer } from '../context';

import Route from './Route';
import RedirectToLogin from './RedirectToLogin';

const PrivateRoute = ({ component: Component, viewer, ...restProps }) => (
	<Route
		{...restProps}
		render={(props) => {
			if (viewer.isAnonymous) {
				return <RedirectToLogin />;
			}

			return <Component {...props} />;
		}}
	/>
);

export default withContext(PrivateRoute, {
	viewer: Viewer,
});
