import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles({
	root: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
		width: '98%',
		height: '75%',
		borderRadius: '50px',
		boxShadow: ' 1px 2px 8px grey',
	},
	input: {
		marginLeft: 8,
		flex: 1,
	},
});

export default function CustomizedInputBase(props) {
	const classes = useStyles();
	return (
		<Paper className={classes.root} elevation={0}>
			<IconButton className={classes.iconButton} aria-label="Search">
				<SearchIcon />
			</IconButton>
			<InputBase
				id="search"
				className={classes.input}
				placeholder="Escribe para buscar"
				inputProps={{ 'aria-label': 'Escribe para buscar' }}
				onChange={props.onChange}
			/>
		</Paper>
	);
}
