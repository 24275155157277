export default {
	root: {
		borderRadius: '100%',
	},
	like: {
		backgroundColor: 'rgb(29,209,161)',
		width: '69px',
		height: '69px',
		color: 'white',
		marginLeft: '18.5px',
		marginTop: '5%',
		padding: '3px 10px 10px 10px',
	},
	dislike: {
		backgroundColor: 'rgb(255,107,107)',
		width: '69px',
		height: '69px',
		color: 'white',
		marginTop: '5%',
		padding: '10px 10px 3px 10px',
	},
	image: {
		width: '29pt',
	},
};
